import styled from 'styled-components'
import { Link } from 'gatsby'

const GridStore = styled(Link)`
position: relative;
> div {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s !important;
  border-radius: 2px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    border: 1px solid #fff;
  }

}
> div img {
  transition: all 0.3s ease 0s !important;
  
}
.fa-plus {
  transition: all 0.3s ease 0s !important;
  color: rgba(17,88,159,0.9);
}
> span {
  z-index: 10;
  color: ${props => props.theme.colors.primary};
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[1]};
  padding: ${props => props.theme.space[4]};
}
> span > span {
  background: #000;
  opacity: 0.8;
  color: #fff;
  border-radius: 6px;
  padding: ${props => props.theme.space[1]};
  box-shadow:
  0 2.5px 1.7px -5px rgba(0, 0, 0, 0.07),
  0 6px 4px -5px rgba(0, 0, 0, 0.05),
  0 11.3px 7.5px -5px rgba(0, 0, 0, 0.042),
  0 20.1px 13.4px -5px rgba(0, 0, 0, 0.035),
  0 37.6px 25.1px -5px rgba(0, 0, 0, 0.028),
  0 90px 60px -5px rgba(0, 0, 0, 0.02);
}
  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[2]};
    padding: ${props => props.theme.space[4]};
    bottom: 2rem;
    color: #000;
    font-weight: 400;
  }
}
&:hover, :active, :focus {
  > div img {
    transform: scale(1.05);
  }
  > span {
    color: #000;
  }
  .productWrapper {
    background-color: rgba(0,0,0,0.3);
  }
  .productWrapper .productPrice {
    transform: scale(1.1);

    @media (max-width: ${props => props.theme.breakpoints[3]}) {
    }
  }
  .productWrapper .productInfo {
    transform: scale(1.05);
  }
  .fa-plus {
    transform: rotate(45deg);
    color: #e2e2e2;
  }
}
> .productWrapper {
  z-index: 9;
  position: absolute !important;
  background-color: rgba(266,266,266,0.4);
  top: 90%;
  height: 10%;
  transition: 0.3s;
  border: 0;

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    top: 80%;
    height: 20%;  
    background-color: rgba(266,266,266,0.5);
  }
}
> .productWrapper .productPrice {
  z-index: 10;
  letter-spacing: 0.025em;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0; 
  font-weight: 300;
  font-size: ${props => props.theme.fontSizes[0]};
  padding: ${props => props.theme.space[2]};
  margin: ${props => props.theme.space[4]};
  background-color: #000;
  border-radius: 12px;
  opacity: 0.8;
  transition: all 0.3s ease 0s !important;

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[1]};
    padding: ${props => props.theme.space[2]};
    border-radius: 12px;
  }

}
> .productWrapper .productInfo {
  z-index: 11;
  color: white;
  position: absolute;
  left: 0;
  bottom: 0; 
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[0]};
  padding: ${props => props.theme.space[2]};
  margin: ${props => props.theme.space[4]};
  transition: 0.3s;

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    font-size: ${props => props.theme.fontSizes[0]};
    padding: ${props => props.theme.space[0]};
  }

}
`

export default GridStore
