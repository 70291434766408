import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { config, animated, useSpring } from 'react-spring'
import Layout from '../components/layout'
import GridStore from '../components/grid-store'
import GridAbout from '../components/grid-about'
import SEO from '../components/SEO'
import {AnimatedBox} from '../elements'
import { ChildImageSharp } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

type PageProps = {
  data: {
    store: {
      nodes: {
        product_title: string
        product_price: string
        product_link: string
        product_cover: ChildImageSharp
      }[]
    }
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 50vw;
  background-color: ${props => props.theme.colors.store};
  background-image: ${props => props.theme.backgrounds[0]};
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 70vh;
  }
`
const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  background-color: ${props => props.theme.colors.store};
  background-image: ${props => props.theme.backgrounds[0]};
`
const Description = styled(animated.div)`
  background-color: ${props => props.theme.colors.store};
  padding: 20px 20px;
  border-radius: 6px;
  max-width: 600px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  > p {
    white-space: pre-line;
  }
  > h1 {
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }
  > em {
    color: ${props => props.theme.colors.primary};
  }
  box-shadow:
  0 2.5px 1.7px -5px rgba(0, 0, 0, 0.07),
  0 6px 4px -5px rgba(0, 0, 0, 0.05),
  0 11.3px 7.5px -5px rgba(0, 0, 0, 0.042),
  0 20.1px 13.4px -5px rgba(0, 0, 0, 0.035),
  0 37.6px 25.1px -5px rgba(0, 0, 0, 0.028),
  0 90px 60px -5px rgba(0, 0, 0, 0.02);
`

const Tagline = styled(AnimatedBox)`
  letter-spacing: 0.05em;
  font-size: ${props => props.theme.fontSizes[1]};
  text-transform: uppercase;
`

const Products: React.FunctionComponent<PageProps> = ({ data: { store } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const taglineAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(-30px, 0, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)'},
  })


  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout color="#e2e2e2">
      <SEO title="STORE | ASC Studio" />
      <Area style={pageAnimation}>
      <GridAbout>
      <PBox py={8} px={[6, 6, 8, 10]}>
      <Description style={descAnimation}>
        <h1>Art Store</h1>
        <Tagline style={taglineAnimation}>Fine Art Giclée Prints</Tagline>
          <p>A considered selection of our favorite original prints! 
             Produced using the finest giclée printing on 350 gsm 100% cotton rag paper.</p>
          <p><span> Ranging in size from A3 to A2</span><em> (29.7 x 42.0cm / 42.0 x 59.4cm). </em> 
          <span>Framed in a variety of finishes to suit any space.</span></p>
          <p>Easy peasy lemon squeezy! We ship each peice finished, framed and ready to hang. </p>
          <em>If you wish to purchase an unframed print or for bulk and commercial orders, please <a href="/contact">contact us</a>.</em>
          </Description>
      </PBox>
      </GridAbout>
      
        {store.nodes.map(product => (
          <GridStore key={product.product_title} to={product.product_link} aria-label={`View product "${product.product_title}"`}>
            <Img fluid={product.product_cover.childImageSharp.fluid} /> 
            <span><span>{product.product_title}</span></span>
              <div className="productWrapper">
              <p className="productInfo">More Info <FontAwesomeIcon icon={faPlus} /></p>
              <p className="productPrice">{product.product_price}</p>
              </div>
          </GridStore>
        ))}
      </Area>
    </Layout>
  )
}

export default Products

export const query = graphql`
  query Products {
    store: allStoreYaml {
      nodes {
        product_title
        product_price
        product_link
        product_cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
